:root {
  --headline-font: 'Playfair Display', serif;
  --body-font: 'Roboto', sans-serif;
}

body[data-theme='theme-lemonade'] {
  --headline-font: 'Merriweather', serif;
  --body-font: 'Lato', sans-serif;
}

body[data-theme='theme-wagmo'] {
  --headline-font: 'Libre Baskerville', serif;
  --body-font: 'Lexend', sans-serif;
}
